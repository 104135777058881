<template>
  <div class="merchstorelist">
    <div class="panel-body row">
      <div class="col-sm-9" style="padding-left:0px;">
        <div class="input-group col-sm-3" style="padding-left:15px;">
          <input
            type="text"
            v-model="searchKey"
            class="form-control"
            id="input-store"
            placeholder="Search Store"
            value
            name="voucher"
          />
        </div>
      </div>
      <div class="col-sm-3" style="padding-right:15px;">
        <button
          type="button"
          class="btn merchprof-sbtn"
          style="float: right;"
          @click="handleCreate"
          v-if="userRole !== 5 && userRole !== 6"
        >
          Add New
        </button>
      </div>
    </div>
    <div style="overflow-x: scroll;">
      <table style="min-width: 910px !important;" id="example" class="table table-striped table-border-out">
        <thead>
          <tr>
            <th scope="col" class="head-table">Store Name</th>
            <th scope="col" class="head-table">Email</th>
            <th scope="col" class="head-table">Phone</th>
            <th scope="col" class="head-table">Holiday Mode</th>
            <th scope="col" class="head-table" v-if="userRole !== 5 && userRole !== 6">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tableItems" :key="item.store_name">
            <td>{{ item.store_name }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.phone }}</td>
            <td style="font-weight: 800;" :style="item.active_status === 2 ? 'color: green;' : 'color: gray;' ">{{ item.active_status === 1 ? "Disable" : "Enable" }}</td>
            <td class="action-style-store" v-if="userRole !== 5 && userRole !== 6">
              <button
                type="button"
                class="btn btn-sm btn-light"
                @click="copyUrl(item._id)"
              >
                Copy URL
              </button>&nbsp;
              <button
                type="button"
                class="btn btn-sm btn-primary"
                style="background-color: #005baa"
                @click="handleBanner(item)"
              >
                Banner</button
              >&nbsp;
              <button
                type="button"
                class="btn btn-sm btn-success"
                style="background-color: #2ba12f"
                @click="handleEdit(item)"
              >
                Edit</button
              >&nbsp;
              <button
                :disabled ="tableItems.length < 2"
                type="button"
                class="btn btn-sm btn-danger"
                style="background-color: #e15437"
                @click="handleDelete(item._id)"
              >
                Delete
              </button>&nbsp;
              <button
                type="button"
                class="btn btn-sm btn-success"
                style="background-color: rgb(3 173 157)"
                @click="handleChangeHolidayMode(item)"
              >
                Holiday Mode
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row text-right">
      <div class="col-sm-12">
        <div class="mt-3">
          <b-pagination
            v-model="currentPage"
            :per-page="pageSize"
            :total-rows="rowcount"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinStore from "@/mixins/merchant/APIStore";
export default {
  mixins: [mixinStore],
  data: function() {
    return {
      userRole: "",
      breadcrumb: [
        {
          text: "Store Management",
          href: "/merchant-store",
          active: true,
        },
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: [],
    };
  },
  watch: {
    searchKey: function(val) {
      this.searchCustomers(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchCustomers(this.searchKey);
    },
  },
  created() {
    this.handleBreadcrumb();
    this.handleGetStores();
    let mdata = JSON.parse(localStorage.merchant_data);
    this.userRole = mdata.role;
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    handleCreate: function() {
      this.$router.push({ name: "Store Configure", params: { id: 0 } });
    },
    handleEdit: function(item) {
      this.$router.push({ name: "Store Configure", params: { id: item._id } });
    },
    handleChangeHolidayMode: function(item) {
      this.$router.push({ name: "Store Configure Holiday Mode", params: { id: item._id } });
    },
    handleGetStores: async function() {
      try {
        let response = await this.getStores();
        this.tableItems = response
        this.storeListData = response;
        // this.searchCustomers(response);
      } catch (error) {
        console.log(error);
      }
    },
    searchCustomers: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.storeListData.slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      } else {
        this.tableItems = this.storeListData
          .filter((obj) => {
            {
              return (
                (obj.store_name &&
                  obj.store_name
                    .toLowerCase()
                    .includes(keyword.toLowerCase())) ||
                (obj.description &&
                  obj.description.toLowerCase().includes(keyword.toLowerCase()))
              );
            }
          })
          .slice(0, pagesize);

        this.rowcount = this.storeListData.length;
      }
    },
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;

      if (currentPage === 1) {
        startsize = 0;
      } else {
        startsize = endsize - pagesize;
      }

      this.tableItems = this.storeListData.slice(startsize, endsize);
    },
    handleDelete: async function(id) {
      try {
        let self = this;
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.value) {
            self.deleteStore(id);
            this.$swal(
              "Deleted!",
              "Store has been deleted successfully",
              "success"
            );
          }
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Delete!", "Error", {
          position: "topRight",
        });
      }
    },
    deleteStore: async function(id) {
      try {
        let status = await this.deleteStores(id);
        this.handleGetStores();
        
        let isUpdate = true
        this.$store.commit("setActiveStore", {
          action: isUpdate,
          payload: null
        });
        
      } catch (error) {
        console.log(error);
      }
    },
    handleBanner: function(item) {
      this.$router.push({ name: "Store Banner", params: { id: item._id } });
    },
    copyUrl: function (id) {
      // let currUrl = window.location.href.split("merchant-store");
      // let newUrl = currUrl[0] + "storeProducts/" + id;

      // let url = "http://3.136.49.247:3091/" + "storeProducts/" + id;
      let url = "https://cochchi.lk/" + "storeProducts/" + id;

      // ttp://124.43.130.172:3063/api/v1/
      // let url = currUrl;
      
      // setTimeout(async () => {
      //   const text = await navigator.clipboard.readText(newUrl);
      //   console.log(text);
      // }, 2000);

      // await navigator.clipboard.writeText(url);
      this.$copyText(url);
      this.$swal.fire({
        position: "top-end",
        type: "success",
        title: "Copied!",
        showConfirmButton: false,
        timer: 800,
      });
    },
  },
};
</script>
<style>
.avatar-style {
  width: 100px;
  text-align: center;
}
.img-avatar,
.avatar > img,
.img-circle {
  vertical-align: middle;
}
.action-style-store {
  width: 400px;
}
.head-table {
  background: white !important;
  text-align: left;
}
.table-border-out {
  border: 1px solid #ddd;
}
.merchstorelist .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
}
.merchstorelist .merchprof-sbtn:hover {
  color: #000000;
}
</style>
